// components/AddVacancyModal.tsx
'use client';
import { citiesRu } from '@/constants/Cities';
import { MAX_AMMOUNT_OF_IMAGES_UPLOAD } from '@/constants/Constants';
import { PhoneOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Select,
  SelectProps,
  Upload,
  UploadFile,
  message
} from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateInformation, validatePhoneNumber } from './validation';
// import sendMessage from '../../api/sendMessage';
interface AddVacancyModalProps {
  //   onClose?: () => void;
  setModalVisible: any;
  setPostResponseFunc: any;
}
const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const postVacancy = async (values: any) => {
  console.log(`values fly in`, values);
  try {
    // Create a FormData object and append the 'data' field
    const formData = new FormData();
    formData.append('data', JSON.stringify(values)); // Assuming 'data' is the field name

    const response = await fetch('/api/sendMessage', {
      method: 'POST',
      body: formData
    });

    if (!response) {
      // Handle the case where the API request is not successful
      console.log(`we return response as !response.ok`);
      return '!response.ok';
      // throw new Error('API request failed');

      // You might want to throw an error or handle it accordingly
    }

    console.log(`postVacancy response`);
    const jsonResponse = await response.json();
    console.log(`we send vacancy from IP ${jsonResponse.ip}`);
    console.log(`response after sendMessage data`, jsonResponse.data);

    if (values.upload?.file) {
      const responseFromImagePost = await fetch('/api/sendImages', {
        method: 'POST',
        // body: JSON.stringify(values.upload.file)
        body: JSON.stringify({
          file: values.upload.file,
          insertId: jsonResponse.data
        })
      });
      console.log(`responseFromImagePost`, responseFromImagePost);
    }

    console.log(`we return response as response.ok`);
    return 'response.ok';
  } catch (error) {
    console.error('Error fetching vacancies:', error);
  }
};

const AddVacancyModal: React.FC<AddVacancyModalProps> = ({
  setModalVisible,
  setPostResponseFunc
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  // const [inputValue, setInputValue] = useState<number[]>([MIN_PAY, MAX_PAY]);
  const [disabled, setDisabled] = useState(true);

  // const toggle = () => {
  //   const currentlyDisabled = !disabled;
  //   setDisabled(currentlyDisabled);

  //   currentlyDisabled
  //     ? form.setFieldsValue({ payGrade: undefined })
  //     : form.setFieldsValue({ payGrade: inputValue });
  // };

  const onFinish = async (values: any) => {
    console.log('Received values:', values);
    // await uploadImage(values.upload.file, `13`);
    try {
      setLoading(true); // Set loading to true on form submission
      console.log('Received values:', values);
      const result = await postVacancy(values);
      // Additional logic after form submission if needed
      console.log(`we get result from postVacancy function `);
      console.log(result);
      //After posting vacancy we need to post images after its all good went down
      // const resultPostingImage = await postImage(values);

      //if we get error we dont close
      if (result === '!response.ok') {
        setPostResponseFunc({ resultOfPost: result, time: Date.now() }); //lifting state up
      } else {
        setModalVisible(false); //close modal

        setPostResponseFunc({ resultOfPost: result, time: Date.now() }); //lifting state up
        // Log the value of resultOfPost after the update
        // console.log(`resultOfPost after update: ${resultOfPost}`);
        //render notification that too many requests
      }

      setLoading(false); // Reset loading to false after form submission
    } catch (error) {
      console.error('Error:', error);
      // Handle error if needed
    }
  };

  const options: SelectProps['options'] = [];

  citiesRu.map(({ cityId, label }, index) => {
    options.push({
      label: `${label}`,
      value: cityId
    });
  });

  //#################### UPLOAD COMPONENT IN FUTURE ##############################
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [imagesRC, setImagesRC] = useState<RcFile[]>([]);
  const allowedTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'image/webp',
    'image/tiff',
    'image/bmp',
    'image/heic',
    'image/heif'
  ];

  const beforeUpload = (file: any) => {
    const isAllowedType = allowedTypes.includes(file.type);
    if (!isAllowedType) {
      message.error(`You can only upload ${allowedTypes} file!`);
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error('Image must smaller than 1MB!');
    }
    return isAllowedType && isLt2M;
  };

  const handleChange = ({ fileList }: { fileList: UploadFile<any>[] }) => {
    if (fileList.length > 0) {
      const files = fileList
        .map((file) => file.originFileObj) // Extract File objects from UploadFile
        .filter((file): file is RcFile => !!file); // Filter out undefined values

      console.log(`we uploadImage`, files);
      setImagesRC(files);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  // const onChangePayGrade = (value: any) => {
  //   const newValue = [...inputValue];
  //   if (`min` in value) {
  //     if (value.min < newValue[1]) {
  //       newValue[0] = value.min;
  //     } else {
  //       newValue[1] = value.min;
  //     }
  //     setInputValue(newValue);
  //   }
  //   if (`max` in value) {
  //     if (value.max < newValue[0]) {
  //       newValue[0] = value.max;
  //     } else {
  //       newValue[1] = value.max;
  //     }
  //     setInputValue(newValue);
  //   }

  //   if (typeof value === 'number') {
  //     const newValue = [...inputValue];

  //     setInputValue(newValue);
  //     form.setFieldsValue({ payGrade: newValue });
  //   } else if (Array.isArray(value)) {
  //     setInputValue(value);
  //     form.setFieldsValue({ payGrade: value });
  //   }
  // };

  return (
    <>
      <Modal
        maskClosable={false}
        open={true}
        title={t('ADD_NEW_VACANCY')}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            {t(`COMMON_CANCEL`)}
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="vacancyForm"
            loading={loading}
          >
            {t(`COMMON_SUBMIT`)}
          </Button>
        ]}
      >
        <Form
          form={form}
          id="vacancyForm"
          onFinish={onFinish}
          layout="vertical"
          disabled={loading}
          onChange={() => {
            console.log(`form.getFieldsValue(true);`, form.getFieldsValue());
          }}
        >
          <Form.Item
            label={t(`COMMON__LOCATION`)}
            name="location"
            rules={[{ required: true, message: 'Please enter the location' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                typeof option?.label === 'string' &&
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                console.log(value);
              }}
              options={options}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label={t(`COMMON__INFORMATION`)}
            name="information"
            rules={[
              {
                required: true,
                min: 100,
                message: `${t(`COMMON__INFORMATION`)} be at least 100 characters`
              },
              { validator: (_, value) => validateInformation(t, _, value) }
            ]}
          >
            <Input.TextArea placeholder={t(`COMMON_ENTER_INFORMATION`)} rows={4} maxLength={500} />
          </Form.Item>

          <Form.Item
            label={t(`COMMON__CONTACT`)}
            name="contactInfo"
            rules={[
              { required: true, max: 10 },
              { validator: (_, value) => validatePhoneNumber(t, _, value) }
            ]}
          >
            <Input
              placeholder={t(`COMMON_ENTER_CONTACT_INFORMATION`)}
              prefix={<PhoneOutlined />}
              maxLength={10}
            />
          </Form.Item>
          {/*   <Form.Item label={t(`COMMON__PAY_GRADE`)} name="payGrade">
            <Switch onChange={toggle} />
            <div style={{ pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1 }}>
              <Slider
                disabled={disabled}
                range
                min={MIN_PAY}
                max={MAX_PAY}
                onChange={onChangePayGrade}
                value={inputValue}
              />
              <InputNumber
                disabled={disabled}
                min={MIN_PAY}
                max={MAX_PAY}
                style={{ margin: '0 16px' }}
                value={inputValue[0]}
                onChange={(value) => onChangePayGrade({ min: value })}
              />
              <InputNumber
                disabled={disabled}
                min={MIN_PAY}
                max={MAX_PAY}
                style={{ margin: '0 16px' }}
                value={inputValue[1]}
                onChange={(value) => onChangePayGrade({ max: value })}
              />
            </div>
          </Form.Item> */}
          <>
            <Form.Item name="upload" label="Upload">
              <Upload
                listType="picture-card"
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {imagesRC.length >= MAX_AMMOUNT_OF_IMAGES_UPLOAD ? null : (
                  <Button icon={<UploadOutlined />} />
                )}
              </Upload>
            </Form.Item>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage('')
                }}
                src={previewImage}
              />
            )}
          </>
        </Form>
      </Modal>
    </>
  );
};

export default AddVacancyModal;
