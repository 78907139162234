'use client';
import { citiesRu } from '@/constants/Cities';
import { SearchOutlined } from '@ant-design/icons';
import { Button, SelectProps } from 'antd';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SearchButton = () => {
  const [showInput, setShowInput] = useState(false);
  const { t } = useTranslation();
  const [currentLocation, setCurrentLocation] = useState<any>();

  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const router = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    const currentLocation = searchParams.get(`location`) || null;
    setCurrentLocation(currentLocation);
  }, [searchParams]);

  const handleSearchChange = (label: string[]) => {
    setDropdownOpen(open);

    // Convert the data to query parameters
    const params = new URLSearchParams({ location: label ? label.toString() : '' });

    const url =
      label && label.length > 0 && label[0] !== 'Весь Израиль'
        ? `/vacanciesPage/1?${params}`
        : '/vacanciesPage/1';
    console.log(`url`, url);

    router.push(url);
  };
  const handleButtonClick = () => {
    setShowInput(!showInput);
  };

  const options: SelectProps['options'] = [];

  citiesRu?.map(({ label }, index) => {
    options.push({
      label: `${label}`,
      value: label
    });
  });

  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {/* <Select
        allowClear
        virtual={false}
        key={currentLocation}
        placeholder={t(`COMMON__LOCATION`)}
        maxTagCount={2}
        defaultValue={null}
        value={currentLocation ? currentLocation.split(',') : []}
        onChange={handleSearchChange}
        options={options && options}
        style={{
          paddingRight: '8px',
          marginLeft: 10,
          width: showInput ? '200px' : '0',
          transition: 'width 0.3s ease-in-out, opacity 0.3s ease-in-out, padding 0.3s ease-in-out',
          opacity: showInput ? 1 : 0
        }}
        dropdownRender={(menu) => {
          return <StyledDropDown>{menu}</StyledDropDown>;
        }}
        onPopupScroll={() => {
          console.log(`onPopupScroll`);
          document.body.style.overflow = 'hidden';
          // document.body.style.height = '100%';
          document.body.style.touchAction = 'none';
        }}
        // open={open}
        onDropdownVisibleChange={setOpen}
      /> */}
      <label id="search-button-label" style={{ display: 'none' }}>
        SearchButton
      </label>
      <select
        className="SearchButton"
        disabled={!showInput}
        style={{
          width: showInput ? '130px' : '0',
          transition: 'width 0.3s ease-in-out, opacity 0.3s ease-in-out, padding 0.3s ease-in-out',
          opacity: showInput ? 1 : 0,
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
          height: '32px',
          lineHeight: '32px',
          paddingLeft: '12px',
          fontSize: '14px',
          appearance: 'none', // Remove default dropdown arrow
          backgroundColor: '#fff',
          // backgroundImage: `url('data:image/svg+xml;charset=UTF-8,%3csvg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M7.247 11.14l-4.796-5.481C2.12 5.208 2.561 4.5 3.321 4.5h9.358c.76 0 1.201.708.87 1.159l-4.796 5.481c-.375.429-1.087.429-1.462 0z"%3e%3c/path%3e%3c/svg%3e')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 8px center',
          cursor: 'pointer'
        }}
        onChange={(e) => {
          console.log(`onChange`, e.target.value);
          handleSearchChange([e.target.value]);
        }}
      >
        {citiesRu.map(({ label }, index) => (
          <option key={index} value={label}>
            {label}
          </option>
        ))}
      </select>

      <Button type="primary" icon={<SearchOutlined />} onClick={handleButtonClick}></Button>
    </div>
  );
};

export default SearchButton;
